.keyboard {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 10px
}

.keyboard__button--zero {
    grid-column: 1/3;
    grid-row: 5;
}

.keyboard__button--plus {
    grid-column: 4;
    grid-row: 2 / 4;
}

.keyboard__button--equal {
    grid-column: 4;
    grid-row: 4 / 6;
}