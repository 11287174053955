.display {
    border-width: 2px;
    border-style: solid;
    border-color: var(--btn);
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 3rem;
}

.display--magic {
    border-color: var(--magic);
}

.display--no-magic {
    border-color: var(--no-magic);
}