:root {
  --btn: #ccc;
  --magic: #cfc;
  --no-magic: #ccc /* #fcc */;
}

.main {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  hyphens: auto;
}

.calc {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  padding: 20px;
}

.calc_display {
  height: 25%;
  margin-bottom: 10px;
}

.calc_keyboard {
  flex: 1 0 auto;
}